import React, { useState, useEffect } from "react";
import { fetchData } from "../../data/Data";
import Heading from "../../common/Heading";
import "./style.css";

const Business_post = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchData().then(fetchedData => {
      console.log("Fetched Data:", fetchedData); // Debugging line
      setPosts(fetchedData.business_post);
    });
  }, []);

  return (
    <>
      <section className='location padding'>
        <div className='container'>
          <Heading title='Explore Our Other Business' subtitle='Those are our great products explore and contact us ' />

          <div className='content grid3 mtop'>
            {posts.map((item, index) => (
              <div className='box' key={index}>
                <img src={`https://cms.jabulanimerkato.com/storage/${item.image_path}`} alt={item.title} />
                <div className='details'>
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                  <p>Phone: {item.phone_number}</p>
                  <p>Address: {item.address}</p>
                  <a href={`/contact`} target='_blank' rel='noopener noreferrer'>contact</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Business_post;
