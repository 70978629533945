import axios from 'axios';

// Base API URL for dynamic data fetching
const apiUrl = 'https://cms.jabulanimerkato.com/api'; // Adjust if necessary

// Function to fetch dynamic data from Laravel API
export const fetchData = async () => {
  try {
    const aboutResponse = await axios.get(`${apiUrl}/about`);
    const businessPostsResponse = await axios.get(`${apiUrl}/business-posts`);
    const awardsResponse = await axios.get(`${apiUrl}/awards`);
    const logoResponse = await axios.get(`${apiUrl}/logo`);
    const carouselImagesResponse = await axios.get(`${apiUrl}/carousel-images`);

    // Return the fetched data as an object
    return {
      about: aboutResponse.data,
      business_post: businessPostsResponse.data,
      awards: awardsResponse.data,
      logo: logoResponse.data,
      carouselImages: carouselImagesResponse.data,
    };
  } catch (error) {
    console.error('Error fetching data:', error);

    // Return empty data structures in case of error
    return {
      about: {},
      business_post: [],
      awards: [],
      logo: {},
      carouselImages: [],
    };
  }
};


// Static data
export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "blog",
    path: "/blog",
  },
  {
    text: "contact",
    path: "/contact",
  },
];

export const featured = [
  {
    cover: "../images/hero/shop.png",
    name: "Shop",
    
  },
  {
    cover: "../images/hero/office.png",
    name: "Offices",
    
  },
  {
    cover: "../images/hero/beauty-salon.png",
    name: "Beauty salon",
   
  },
  {
    cover: "../images/hero/bank.png",
    name: "Banks",
    
  },
  {
    cover: "../images/hero/travel.png",
    name: "Travel angency office",
    
  },
   {
    cover: "../images/hero/cafe.png",
    name: "Cafteria",
   
  },
];

export const list = [
  {
    id: 1,
    cover: "../images/list/p-1.png",
    name: "Red Carpet Real Estate",
    location: "210 Zirak Road, Canada",
    category: "For Rent",
    price: "$3,700",
    
  },
  {
    id: 2,
    cover: "../images/list/p-2.png",
    name: "Fairmount Properties",
    location: "5698 Zirak Road, NewYork",
    category: "For Sale",
    price: "$9,750",
    
  },
  {
    id: 3,
    cover: "../images/list/p-7.png",
    name: "The Real Estate Corner",
    location: "5624 Mooker Market, USA",
    category: "For Rent",
    price: "$5,860",
   
  },
  {
    id: 4,
    cover: "../images/list/p-4.png",
    name: "Herringbone Realty",
    location: "5621 Liverpool, London",
    category: "For Sale",
    price: "$7,540",
    
  },
  {
    id: 5,
    cover: "../images/list/p-5.png",
    name: "Brick Lane Realty",
    location: "210 Montreal Road, Canada",
    category: "For Rent",
    price: "$4,850",
    
  },
  {
    id: 6,
    cover: "../images/list/p-6.png",
    name: "Banyon Tree Realty",
    location: "210 Zirak Road, Canada",
    category: "For Sale",
    price: "$2,742",
    
  },
];

export const footer = [
  {
    title: "LAYOUTS",
    text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Service Page" }, { list: "Property Page" }, { list: "Contact Page" }, { list: "Single Blog" }],
  },
  {
    title: "ALL SECTIONS",
    text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
  },
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  },
];

// Initial placeholders for dynamic data
export const about = {
  title:[
    "Our Story",
  ],
  description: [
    "Placeholder description text. This will be replaced by dynamic content from the backend.",
  ],
  image: "./placeholder.jpg",
};

export const awards = [
  {
    icon: <i className='fa-solid fa-trophy'></i>,
    num: "Placeholder Award",
    image: './placeholder.jpg',
  },
];

export const business_post = [
  {
    id: 1,
    name: "Placeholder City",
    cover: "./placeholder.jpg",
    description: "This will be replaced by dynamic content from the backend.",
    contact: {
      phone: "+1 000-000-0000",
      address: "This will be replaced by dynamic content from the backend.",
      link: "https://www.placeholder.com",
    },
  },
];

export const logo = "./placeholder-logo.png";

export const carouselImages = [
  {
    src: '/images/placeholder.jpg',
    alt: 'Placeholder Banner'
  },
];

// Use the fetchData function in your components to replace placeholders with real data
