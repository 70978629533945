import React, { useEffect, useState } from "react";
import Heading from "../../common/Heading";
import "./recent.css";
import RecentCard from "./RecentCard";
import { fetchProperties } from "../../data/blog"; // Import the fetchProperties function

const Recent = () => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    // Fetch properties data when the component mounts
    const getProperties = async () => {
      const fetchedProperties = await fetchProperties();
      setProperties(fetchedProperties); // Set the properties state
    };

    getProperties();
  }, []);

  return (
    <>
      <section className='recent padding'>
        <div className='container'>
          <Heading title='Recent Property Listed' subtitle='Here are the latest available properties in our building' />
          <RecentCard properties={properties} />
        </div>
      </section>
    </>
  );
};

export default Recent;
