import React from "react"
import Awards from "./awards/Awards"
import Featured from "./featured/Featured"
import Hero from "./hero/Hero"
import Business_post from "./business_post/Business_post"

import Recent from "./recent/Recent"


const Home = () => {
  return (
    <>
      <Hero />
      <Featured />
      <Recent />
      <Awards />
      <Business_post />
     
      
    </>
  )
}

export default Home
