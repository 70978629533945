import React, { useState, useEffect } from "react";
import "./footer.css";
import { fetchData } from "../../data/Data"; // Import the fetchData function

const Footer = () => {
  const [logoUrl, setLogoUrl] = useState(""); // State to hold the logo URL

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData().then(fetchedData => {
      console.log(fetchedData); // Debug: Log the fetched data
      if (fetchedData.logo && fetchedData.logo.length > 0) {
        setLogoUrl(`https://cms.jabulanimerkato.com/storage/${fetchedData.logo[0].image_path}`);
      } else {
        console.error("Logo data not found"); // Debug: Log error if logo is not found
      }
    }).catch(error => {
      console.error("Error fetching data:", error); // Debug: Log if there's an error fetching data
    });
  }, []);

  return (
    <>
      <footer>
        <div className="container">
          <div className="logo-section">
            {logoUrl ? (
              <img 
                src={logoUrl} 
                alt="Logo" 
                className="footer-logo"
              />
            ) : (
              <p>Logo not available</p> // Debug: Show message if logo is not available
            )}
            <h2>Do You Need Help With Anything?</h2>
            <a href="/contact" className="contact-button">Contact Us</a>
          </div>

          <div className="contact-section">
            <h3>Contact Us</h3>
            <p>Merkato, Addis Ababa, Ethiopia</p>
            <p>Phone: +251 911525150</p>
            <p>Email: Jabuplc2008@gmail.com</p>
          </div>

          <div className="useful-links">
            <h3>Useful Links</h3>
            <ul>
              <li><a href="/home">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/service">Services</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>

          <div className="newsletter">
            <h3>Latest News</h3>
            <p>"Discover our latest blog featuring the newest available propertys perfect for finding your next workspace!"</p>
            <a href="/blog" className="contact-button">blog</a>
          </div>

          {/* New Section for BMS Access */}
          <div className="bms-access">
            <h2>BMS Access</h2>
            <p>
              BMS access click the link:{" "}
              <a href="https://bms.jabulanimerkato.com" target="_blank" rel="noopener noreferrer">
                Building Management System
              </a>
            </p>
          </div>
        </div>
      </footer>
      <div className="legal">
        <span>Copyright skylink technologies© 2024. All rights reserved</span>
      </div>
    </>
  );
};

export default Footer;