import React, { useState, useEffect } from "react";
import Heading from "../../common/Heading";
import { fetchData } from "../../data/Data"; // Import the fetchData function
import "./awards.css";

const Awards = () => {
  const [awardsData, setAwardsData] = useState([]);

  useEffect(() => {
    fetchData().then(fetchedData => {
      if (fetchedData.awards) {
        setAwardsData(fetchedData.awards);
      }
    });
  }, []);

  return (
    <>
      <section className='awards padding'>
        <div className='container'>
          <Heading title='Happy customers still woking with us' subtitle='Our Customers' />

          <div className='content grid4 mtop'>
            {awardsData.map((award, index) => (
              <div className='box' key={index}>
                <div className='icon'>
                  <img 
                    src={`https://cms.jabulanimerkato.com/storage/${award.image_path}`} 
                    alt={award.name} 
                  />
                </div>
                <h1>{award.name}</h1>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Awards;
