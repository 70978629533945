import React, { useState } from "react";
import axios from "axios";
import img from "../images/pricing.jpg";
import Back from "../common/Back";
import "./contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://cms.jabulanimerkato.com/api/contact', formData);
      alert('Your message has been sent successfully!');
      // Clear the form
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: ""
      });
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <>
      <section className='contact mb'>
        <Back title='Contact-Us' cover={img} />
        <div className='container'>
          <form className='shadow' onSubmit={handleSubmit}>
            <h4>Fillup The Form</h4> <br />
            <div>
              <input 
                type='text' 
                name='name' 
                placeholder='  Name' 
                value={formData.name} 
                onChange={handleChange} 
                required 
              />
              <input 
                type='email' 
                name='email' 
                placeholder='  Email' 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
            </div>
            <input 
              type='text' 
              name='subject' 
              placeholder='  Subject' 
              value={formData.subject} 
              onChange={handleChange} 
              required 
            />
            <textarea 
              name='message' 
              cols='30' 
              rows='10' 
              placeholder=' Message' 
              value={formData.message} 
              onChange={handleChange} 
              required 
            ></textarea>
            <button type='submit'>Submit Request</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
