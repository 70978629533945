import axios from 'axios';

// Base API URL for fetching building units
const apiUrl = 'https://bms-services.jabulanimerkato.com/api/building-units';

// Function to fetch property data from the API
export const fetchProperties = async () => {
  try {
    const response = await axios.get(apiUrl);
    const propertiesData = response.data.data.data;
    console.log("fgghh", propertiesData);
    
    // Filter properties to only include those with status "Available"
    const availableProperties = propertiesData.filter(
      property => property.status === 'Available'
    );

    // Map the available properties to the desired structure
    return availableProperties.map(property => ({
      id: property.id,
      cover: property.image ? `https://bms-services.jabulanimerkato.com/images/buildingunits/${property.image}` : 'default-image-url',
      name: property.building ? property.building.name : 'Unknown Building', // Check if building is not null
      location: property.building ? property.building.address : 'Unknown Location', // Check if building is not null
      category: 'For Sale', // Static category as per example
      price: property.price_per_meter_sq ? `ETB ${property.price_per_meter_sq}` : `Estimated Price: ETB ${property.estimate_price}`,
      roomType: `Room Type: ${property.room_type}`,
      floorNumber: `Floor Number: ${property.floor_no}`,
      roomSize: `${property.area_in_sqm || 'N/A'} sqm`, // Account for null area
      status: property.status,
      contact: 'Contact Us: +251 911 123 456', // Static contact information
    }));
  } catch (error) {
    console.error('Error fetching properties:', error);
    return [];
  }
};