import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { fetchData } from "../../data/Data";
import "./hero.css";

const Hero = () => {
  const [carouselImages, setCarouselImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetchData().then(fetchedData => {
      if (fetchedData.carouselImages) {
        setCarouselImages(fetchedData.carouselImages);
      }
    });
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselImages.length) % carouselImages.length);
  };

  return (
    <section className='hero'>
      <div className='container'>
        <Carousel
          className="Carousel"
          selectedItem={currentIndex}
          onChange={(index) => setCurrentIndex(index)}
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          interval={5000}
          swipeable={false}  // Disable swipe gestures
        >
          {carouselImages.map((image, index) => (
            <div key={index}>
              <img
                src={`https://cms.jabulanimerkato.com/storage/${image.image_path}`}
                alt={image.alt || `Slide ${index + 1}`}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Hero;
