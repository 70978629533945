import React, { useEffect, useState } from "react";
import Back from "../common/Back";
import RecentCard from "../home/recent/RecentCard";
import "../home/recent/recent.css";
import img from "../images/about.jpg";
import { fetchProperties } from "../data/blog"; // Assuming you have a fetchProperties function to get data

const Blog = () => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const getProperties = async () => {
      const fetchedProperties = await fetchProperties();
      setProperties(fetchedProperties);
    };

    getProperties();
  }, []);

  return (
    <>
      <section className='blog-out mb'>
        <Back title='Blog Grid - Our Blogs' cover={img} />
        <div className='container recent'>
          <RecentCard properties={properties} />
        </div>
      </section>
    </>
  );
}

export default Blog;
