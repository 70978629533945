import React, { useState, useEffect } from "react";
import "./header.css";
import { nav, fetchData } from "../../data/Data"; // Import fetchData from the correct path
import { Link } from "react-router-dom";

const Header = () => {
  const [navList, setNavList] = useState(false);
  const [logoUrl, setLogoUrl] = useState(""); // State to hold the logo URL

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData().then(fetchedData => {
      // Assuming the logo data is an array and you want to use the first item
      if (fetchedData.logo && fetchedData.logo.length > 0) {
        setLogoUrl(`https://cms.jabulanimerkato.com/storage/${fetchedData.logo[0].image_path}`);
      }
    });
  }, []);

  return (
    <>
      <header>
        <div className='container flex'>
          <div className='logo'>
            {logoUrl ? (
              <img src={logoUrl} alt='Logo' />
            ) : (
              <p>Loading logo...</p>
            )}
          </div>
          <div className='nav'>
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  <Link to={list.path}>{list.text}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className='toggle'>
            <button onClick={() => setNavList(!navList)}>
              {navList ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
