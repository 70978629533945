import React, { useState, useEffect } from "react";
import Back from "../common/Back";
import { fetchData } from "../data/Data"; // Import the fetchData function
import "./about.css";
import Heading from "../common/Heading";

const About = () => {
  const [data, setData] = useState({
    about: { description: "", image_path: "", title: "" },
  });

  useEffect(() => {
    fetchData().then(fetchedData => {
      console.log("Fetched Data:", fetchedData); // Debugging line
      setData({ about: fetchedData.about });
    });
  }, []);

  return (
    <>
      <section className='about'>
        <div >
        <Back className='title'
        
         
          title={`About Us - ${data.about.title || 'Who We Are?'}`} 
          cover={`https://cms.jabulanimerkato.com/storage/${data.about.image_path}`} 
        /></div>
        <div className='container'>
          <div className='left'>
            <div className='description'>
              <Heading title={data.about.title || 'Our building'} />
              {data.about.description ? (
                data.about.description.split("\n").map((text, index) => (
                  <p key={index}>{text}</p>
                ))
              ) : (
                <p>No description available.</p>
              )}
            </div>
          </div>
          <div className='right'>
            {data.about.image_path ? (
              <img 
                src={`https://cms.jabulanimerkato.com/storage/${data.about.image_path}`} 
                alt='About Us' 
                className='about-image'
              />
            ) : (
              <p>No image available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
