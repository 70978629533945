import React from "react";

const RecentCard = ({ properties }) => {
  if (!properties || properties.length === 0) {
    return <p>No properties available.</p>;
  }

  return (
    <div className="content grid3 mtop">
      {properties.map((property, index) => (
        <div className="box shadow" key={index}>
          <div className="img">
            <img
              src={property.cover || 'default-image-url'} // Use the image URL or a default
              alt={property.name || "Property Image"}
            />
          </div>
          <div className="text">
            <div className="category flex">
              <span
                style={{
                  background:
                    property.status === "Available" ? "#25b5791a" : "#ff98001a",
                  color: property.status === "Available" ? "#25b579" : "#ff9800",
                }}
              >
                {property.status || "Unknown Status"}
              </span>
              
            </div>
            <h4>{property.name || "Unknown Building"}</h4>
            <p>
              <i className="fa fa-location-dot"></i> {property.location || "Unknown Location"}
            </p>
            <p>
              <strong>Room Type:</strong> {property.roomType || "N/A"}
            </p>
            <p>
              <strong>Floor Number:</strong> {property.floorNumber || "N/A"}
            </p>
            <p>
              <strong>Area:</strong> {property.roomSize || "N/A"}
            </p>
          </div>
          <div className="button flex">
            <div>
              <button className="btn2">
                {property.price || "N/A"}
              </button>
              <label htmlFor=""></label>
            </div>
            <span>{property.status || "N/A"}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecentCard;
